import Dh from "@/helpers/date.helper";

import DeleteModal from "../Modals/DeleteModal.vue";

import ActionPlanService from "@/services/action.plan.service";
const service = new ActionPlanService();

export default {
  name: "ActionPlansList",

  components: {
    DeleteModal
  },

  data() {
    return {
      showDeleteModal: false,
      actionPlan: null,

      actionPlans: [],
      currentPage: 1,
      perPage: 25,
      totalRows: 0
    }
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      this.actionPlans = [];

      const res = await service.list(this.perPage, skip);
      if (res && !res.error) {
        this.actionPlans = res.data.actionPlans;
        this.totalRows = res.data.count;
      }
    },

    async addActionPlan() {
      await this.$router.push({name: 'ActionPlansCreate'});
    },

    async editActionPlan(actionPlan) {
      await this.$router.push({name: 'ActionPlansDetails', params: {id: actionPlan._id}});
    },

    toggleDeleteModal(actionPlan) {
      this.actionPlan = actionPlan || null;
      this.showDeleteModal = !this.showDeleteModal;

    },

    onDeleteActionPlan({actionPlanId}) {
      this.showDeleteModal = false;

      const index = this.actionPlans.findIndex(el => el._id === actionPlanId);
      if (index > -1) {
        this.actionPlans.splice(index, 1);
        this.actionPlan = null;
      }
    },

    async setPerPage(num) {
      this.perPage = +(num);
      this.$hideModals();
      await this.list();
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },
  },

  filters: {
    createdAt(date) {
      return Dh.parseDate(date);
    },
  }
};